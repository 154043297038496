import React from 'react'; 
import { BrowserRouter as Router, Redirect, Switch, Route, withRouter} from 'react-router-dom'; 
import Charts from '../components/Charts';




const Page = ({   history, auth, match, logOut , ...other }) => {  
    if (!auth) return <Redirect to="/login" />
    const {path} = match;
    let tagHeader = React.createRef();




    const scrollToTop = e => { 
        window.scrollTo(0, 0);
    }

    const exitNow = e => { 
        logOut();
    }
    return (
        <div className="charts">   
<header>
  <div className="navbar navbar-dark bg-dark shadow-sm">
    <div className="container d-flex justify-content-between">
      <a href="#" className="navbar-brand d-flex align-items-center">
         
        <strong>Chart Punarjeeva</strong>
      </a>
      <span onClick={ exitNow } className="cursor-pointer text-white text-center p-2" title="Logout"  >
      <span className="icofont-exit"></span> 
      </span>
    </div>
  </div>
</header>
<main role="main"> 

<Router>  
            <Switch> 
 
 
    <Route  exact  path={ `${path}` } component={( props ) => <Charts  history={history}   /> } />

        
        
    </Switch>
</Router>
</main>

<footer className="text-muted">
  <div className="container">
    <p className="float-right">
      <a onClick={ scrollToTop }>Back to top</a>
    </p> 
    <p>&copy; Punarjeeva Technologies 2020</p>
  </div>
</footer>

        </div>
    );
}





export default Page; 
 
