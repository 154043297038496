import React, { Component, Fragment } from "react"; 

import Logo from '../assets/image/logo/only-logo.png';
 


class Login extends Component {
    constructor(props) {
        super(props);



        this.state = { 
           username : 'chart@punarjeeva.com',
           password : '',
           disabled: false,
           error:{
               message:  null
           }
        }
      


       
 
    }


     handleSubmit = (form) => {
        form.preventDefault();
        const { disabled } = this.state;
        if( ! disabled){
            const {
                username,
                password
            } = this.state;
            
            const { history } = this.props;

            this.setState( { disabled : true }); 

            if( username === "chart@punarjeeva.com" && password === "Chart@1234" ||
                username === "chartuser@punarjeeva.com" && password === "ChartUser123" ||
                username === "chartuser1@punarjeeva.com" && password === "MyChart1234" ||
                username === "chartuser2@punarjeeva.com" && password === "OfficialChart1234" ) {
                localStorage.auth = true;
                history.href = '/';
                console.log("Login success");
            } else {
                this.setState({ error: { message: "Invalid username or password."}});
             
            }

            this.setState( { disabled : false }); 
            
        }
    }


    handleChange = (event) => {
        const { disabled } = this.state;
        
        const vlaue = event.target.value;
        switch (event.target.name) {
            case "username":
                this.setState({
                    username: vlaue
                });
                break;
            case "password":
                this.setState({
                    password: vlaue
                });
                break;
            }
        this.setState({ error: { message: null}});    
    }


 
  
       
      

    

    render() {
        
        const { error, username, password, disabled } = this.state; 

        const style = disabled ? { cursor: 'wait'} : { cursor: 'default'};
        return <>



        <div className="text-center" style={style}  >
            <form className="form-signin"  method="post"  onSubmit={this.handleSubmit} >
          <div className="d-flex mb-4 align-items-center">
          <img className="logo" src={Logo} alt=""  /> 
          <h3 className=" ">Chart.Punarjeeva</h3>
          </div>
          <div className="form-group">
          <label htmlFor="inputEmail" className="sr-only" name="username" >Email address</label>
          <input type="email" id="inputEmail" className="form-control" placeholder="Email address" required autoFocus name="username" onChange={this.handleChange} value={username}  />
        
          </div>
          <div className="form-group">
          <label htmlFor="inputPassword" className="sr-only" name="password">Password</label>
          <input type="password" id="inputPassword" className="form-control" placeholder="Password" required  name="password" onChange={this.handleChange} value={password}  />
          
          </div>
        <div className="checkbox mb-3">

         {
             error.message &&  <div className="alert alert-danger">
                <p className="m=0">{ error.message}</p>

            </div>
        } 
          </div>
          <button className="btn btn-lg btn-warning btn-block" type="submit"  style={style}>Sign in</button>
          <p className="mt-5 mb-3 text-muted">&copy; Punarjeeva Technologies 2020</p>
        </form>
        </div>


         




        </>
 
    } 
}




export default Login;

