import React, { Component, Fragment } from "react"; 

import Hasth from "./charts/Hasth";
import Shirove from "./charts/Shirove";
import Samatved from "./charts/Samatved";

import CSVReader from 'react-csv-reader';
  

import { svgAsPngUri as html2canvas} from 'save-svg-as-png';
import jsPDF from 'jspdf';


class Chart extends Component {
    constructor(props) {
        super(props);

// , "TRANA"
        this.games = ["HASTH", "SHIROVE", "SAMATVED" ]
        this.csvKeys = { 
          HASTH : [
            "name","age","gender","issue","date","FN1","FN2","FN3","FN4","FN5","FN6","FN7","FN8"
          ],
          SHIROVE: [
            "name",	"age",	"gender",	"issue",	"date",	"FM1",	"FM2",	"FM3",	"FM4",	"FM5",	"FM6",	"FM7",	"FM8",	"FM9",	"FM10", 	"FM11",	"FM12", "B1",	"B2",	"B3",	"B4",	"B5",	"B6",	"B7",	"B8"
                  ],
          SAMATVED : [
            "name",	"age",	"gender",	"issue",	"date",	"FM1",	"FM2",	"FM3",	"FM4",	"FM5",	"FM6",	"FM7",	"FM8",	"FM9",	"FM10",	"FM11",	"FM12",	"B1",	"B2",	"B3",	"B4",	"B5",	"B6",	"B7",	"B8"
          ]
          }
        this.state = { 
          game: null,
          data: [],
          showGame: false,
          error: "",
          placeholder: "Select xlsx file",
          loading: false,
          user: {},
          pdf_gen: false,
          progress: 0
        }
      


       this.pdf_ref = React.createRef();
 
    }





    chartProcess = e => {
      const { game, data } = this.state;
      try {

        const user = {
          name: data[0].name,
          age: data[0].age,
          gender: data[0].gender,
          issue: data[0].issue,
        }
        this.setState({ user });



 
        
      } catch (error) {   
        this.setState({ loading: false, error: ` Invalid file for ${ game } ` });
      } 
    }
 


    dataPreprocess = e => {

      const data = [];
      const { game } = this.state;
      let errorMessage = null;
      const loading = false;
      try {



      const current_key = this.csvKeys[ game ]; 
      let isok = true;
 

      e[0].forEach( (each, index) =>{ 
         if( each.replace(" ", "").toLowerCase() != current_key[ index].replace(" ", "").toLowerCase() ){ isok = false; } 
        } );

       
        if( e[0].length != current_key.length) {
          errorMessage = ` Invalid file for ${ game } `;
          throw new Error( " invalid file ");
        }

      if( isok )  {
        e.splice(0, 1); 
        e.forEach( each => { 
            const dda = {};
            each.forEach( (eachIn, indexIn) => { 
              dda[ current_key[indexIn] ] = eachIn;
            })
            if(dda[ current_key[0] ].length > 1)
              data.push( dda );
        }); 
      } else {
        errorMessage = ` Invalid file for ${ game } `;
        throw new Error( " invalid error ");
      }
   

        
      } catch (error) {  
        errorMessage = ` Invalid file for ${ game } `;
      }
 
      
      this.setState({ data, error: errorMessage , loading  }, e => this.chartProcess());
      
    }



    handleChange = (event) => { 
        const name = event.target.name;
        const value = event.target.value; 
        const state = {};
        state[ name ] = value; 
        this.setState( state );    
    }


 
    changeGame = game => {
      const showGame = false;
      this.setState({ game, showGame });
    }

    selectFile = e => {
      const { loading } = this.state;
      if( ! loading ){
        document.getElementById("fileSelected").value = null; 
        document.getElementById("fileSelected").click(); 
      }
    }
        

    handleForce = data => {

      this.setState({ placeholder: ` ${data.length} rows selected. `,  loading: true });
      this.dataPreprocess( data );

    }
    
    handleDarkSideForce = e => {
      this.setState({ error: "invalid file selected."});
    }

    exitFileBack = e => {
      this.setState( { game: null, placeholder: "Select xlsx file", data: [], loading: false } )
      
    }

    removeContent = e => {
      this.setState({ user: {} })
    }


    asyncMakerFunc = ( doc,  all_imgs, count ) => {
 
      const current_index =  all_imgs.length -  count ;

      this.setState({ 
        progress : parseInt( (current_index / all_imgs.length) * 100 )
      });

      let text_ = "";
      try{
       
      text_ = all_imgs[ current_index ].getElementsByClassName("heading-text")[0].innerText;
       
      if( typeof text_ == "string")
      if(text_.substr(0,2).toLowerCase() == "fo" || text_.substr(0,2).toLowerCase() == "ba" ) {
        text_ = `Billiards - ${ text_ }`;
      }
       
      } catch( err ){}


      const input =  all_imgs[ current_index ].getElementsByTagName("svg")[0];
      const divHeight =input.getClientRects()[0].height;
      const divWidth =input.getClientRects()[0].width;
      const ratio = divHeight / divWidth;

 


      
      html2canvas(input,  {
          height: divHeight,
           width: divWidth
      }) .then((image) => { 
        
        

          // const image = canvas.toDataURL("image/jpeg"); 
          const width = doc.internal.pageSize.getWidth() -20;    
          const cv_height = doc.internal.pageSize.getHeight();
          const height = ratio * width; 

           
          const new_height =  20 +  (( current_index % 3 ) * ( height + 20)   ) ;
 

          if ( current_index % 3 == 0 && current_index != 0   ) {
            doc.addPage('a4', '0');
          }
          if(current_index == 0){
            doc.setFontSize(14);
            // doc.text("Game score charts", 25, 10);




          } else {  
            doc.setFontSize(14); 
            doc.text( text_ , 25, parseInt(new_height + 6 ) );  
          }

 
          
          doc.addImage(image, 'png', 6, parseInt(new_height) + 10  , parseInt(width   ), parseInt(height  ) );
         
          
          if( count - 1 > 0 ){
            this.asyncMakerFunc(doc,  all_imgs, count - 1 )
          } else {

            doc.save('punarjeeva-chart.pdf');  
            this.setState({ pdf_gen : false, progress : 0 });
          } 
          
          

          
        })
      ;






    }


    downloadPdf =  ( e ) => { 

      const { pdf_gen, user, game } = this.state;

      if( !pdf_gen ) {

      const doc = new jsPDF("p", "mm", "a4");  
      const all_imgs = document.getElementsByClassName('pdf-chart');
      const rev_all_imgs =all_imgs;  

      this.setState({ pdf_gen : true });

 
      

      doc.setFontSize(40);
      doc.text("Punarjeeva - Graph Data", 25, 25);
      
      

      doc.setFontSize(15);
      doc.text(`Game`, 20, 55); 
      
        
      doc.setFontStyle("bold");
      doc.text(`: ${game}`, 80, 55);
      
      
      doc.setFontSize(20);
      doc.text("User Details ", 15, 85);
      
      
      let y =100;
       doc.setFontSize(15);
        
      doc.setFontStyle("normal");
      doc.text("Name ", 20, y);
      
        
      doc.setFontStyle("bold");
      doc.text(`: ${user.name}`, 80, y);
      
      
      y += 15; 
      doc.setFontStyle("normal");
      doc.text("Age", 20, y);
      
        
      doc.setFontStyle("bold");
      doc.text(`: ${user.age}`, 80, y);
      y += 15; 
      doc.setFontStyle("normal");
      doc.text("Gender", 20, y);
      
        
      doc.setFontStyle("bold");
      doc.text(`: ${user.gender}`, 80, y);
      
      
      
      y += 15; 
      doc.setFontStyle("normal");
      doc.text("Issues", 20, y);
      
        
      doc.setFontStyle("bold");
      const issues = user.issue.match(/.{1,36}/g);
      issues.forEach( (each, index) => {
        if ( index == 0 ) { 
          doc.text(`: ${each}`, 80, y);
        } else {
          doc.text(`  ${each}`, 80, y+(index * 6));
        }
      });
      




      doc.setFontSize(12);
      doc.setFontStyle("normal");

      doc.text(` ${ new Date().toLocaleDateString()} - ${ new Date().toLocaleTimeString()}`, ( doc.internal.pageSize.getWidth()  - 70), (doc.internal.pageSize.getHeight() - 10 ));

      doc.addPage('a4', '0');

      this.asyncMakerFunc(doc, rev_all_imgs, rev_all_imgs.length );

      }

 
 

    }
    

    render() {
        
        const { user, data, showGame, game, error, placeholder, loading, pdf_gen, progress } = this.state; 

         return <>

         <div className="d-none">

         <CSVReader
        cssClass="d-none" 
        onFileLoaded={this.handleForce}
        onError={this.handleDarkSideForce}
        inputId="fileSelected" 


    />

         </div>
 
     
<div id="pdf-class" ref={this.pdf_ref}>


  <section className="jumbotron pt-3 pb-4 text-center">

      {
        typeof user.name == "undefined" ?
 
       
    <div className="container py-5">
      <h1 className="jumbotron-heading">View Score Chart</h1>
      {
        game ? <p className="lead text-muted"> Upload <strong>{ game }</strong> score xlsx file to view graphical representation. 
        <span className="text-danger d-block"><small>Individual score needs to be uploaded.</small></span> 
        </p>
        : <p className="lead text-muted"> Select game first.  </p>
      
      }
      <div className="d-flex justify-content-center" >

        {
           game ? <div className="dropdown d-flex flex-column">
  
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" onClick= {   this.exitFileBack  } >
          <i className="icofont-arrow-left"></i>
          </span>
        </div>
        <input type="text" className="form-control cursor-pointer"   onClick={ this.selectFile } title="Select xlsx file. " placeholder={ placeholder } readOnly required />
        <div className="input-group-prepend">
          <span className="input-group-text cursor-pointer" title="Select xlsx file. "  onClick={ this.selectFile }>
            {
              loading ? 
              <Fragment>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  <span className="sr-only">Loading...</span>
              </Fragment>
              : <i className="icofont-file-excel"></i> 
            }
          
          </span>
        </div>
          
      </div>  
      
{
  error  ? 
  <div className="alert alert-danger">
{error}
</div>  
: <div />
}
              </div>
           :  
           
           <div className="dropdown d-flex">
  <button onClick={ e=> this.setState( { showGame: true }) } className="btn btn-secondary dropdown-toggle" type="button" >
    { game? game : "Select Game" }
  </button>
  <div className= { showGame ? " dropdown-menu show " : "dropdown-menu " } >
  {
               this.games.map( each => <button key={ each } onClick={ e => this.changeGame ( each ) } className="dropdown-item" type="button">{ each }</button> )
             } 
  </div>
</div> 
        }
       
      </div>
    </div>
     :  <div className="container ">
     <div className="row justify-content-center">
     <span className="back-button" onClick={ this.removeContent }>
             <i className="icofont-line-block-left"></i>
             </span>

           <div className="col-sm-12 col-md-5 text-left">

            

           <div className="form-group">
                 <label>Name</label>
                 <p className="form-control">{ user.name}</p>
             </div>

             <div className="form-group">
                 <label>Age</label>
                 <p className="form-control">{ user.age}</p>
             </div>


             <div className="form-group">
                 <label>Gender</label>
                 <p className="form-control">{ user.gender}</p>
             </div>
             </div>

             <div className="col-sm-12 col-md-5 text-left">

             <div className="form-group">
                 <label>Game</label>
                 <p className="form-control">{ game }</p>
             </div>


             <div className="form-group">
                 <label>Issues</label>
                 <textarea rows="5" className="form-control bg-white" value={ user.issue} readOnly>{ user.issue}</textarea>
             </div>



           </div>

           <div className="col-sm-12  d-flex justify-content-center">
                        <button className="btn btn-sm btn-info d-flex align-items-center" onClick={ e => this.downloadPdf ( e ) }> 
                        {
                         data.length > 0 && pdf_gen ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <div />
                        }  <span className="mx-2"> export pdf</span>   { pdf_gen ? <span>{progress} %</span> : ""  }</button> 
           </div>

     </div> 

 </div>
}
  </section> 

  {
    data.length > 0 ?
    <Fragment>

      <div className="container">

      <div className="album py-5 ">
     <Fragment>
     {
          game === "HASTH" ? <Hasth data={data} datakey={ this.csvKeys[ game ] } /> : <div />
        } 
     </Fragment>
<Fragment>
{
          game === "SHIROVE" ? <Shirove data={data} datakey={ this.csvKeys[ game ] } /> : <div />
        } 

</Fragment>
<Fragment>
{
          game === "SAMATVED" ? <Samatved data={data} datakey={ this.csvKeys[ game ] } /> : <div />
        } 

</Fragment>

      </div>
      </div>

    </Fragment>
    :
    <div />
  }


</div>
        </>
 
    } 
}




export default Chart;

