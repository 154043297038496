import React, { PureComponent, Fragment } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  LineChart, Line, Label 

} from 'recharts';
 


const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    const value = payload[0].value;
    return (
      <div className="custom-tooltip bg-white p-3 border">
        <p className="mb-2">{`Time : ${label}`}</p>
        <p className="m-0 strong">  {  value < 0 ? "Not Attempted" : <strong>{ ` Score : ${value}` }</strong>}</p> 
      </div>
    );
  }

  return null;
};


class siteCharts extends PureComponent { 

    constructor( props ) {
        super( props );

        this.state = {
            chart: [],
            activeIndex: -1,
            subcharts: [],
            all: false
        }
    }

    makeChart = () => {
        const { data, datakey } = this.props;
        const des = [...datakey];
        des.splice(0, 5); 
        const chart = [];
        const ikeys = des;
        


        ikeys.forEach( (each, index) => {
            const modifData = {};
                modifData["name"] = each;
                modifData["count"] = data.filter( eachIn => ! isNaN( eachIn[each])  ).length

            chart.push( modifData );
        });


        const subcharts = [];


        ikeys.forEach( ( each, index ) => {
            const modifData = {};
            modifData["name"] = each;
            const subD = [];
            const arrv = [];
        
            
            data.forEach( (eachIn, indexIn ) => {
                const modifDataIn = {};

                    modifDataIn["name"] = eachIn["date"];  
                    modifDataIn["score"] = isNaN( eachIn[each] ) ? -1 : parseInt( eachIn[each] ) ;   
                    subD.push( modifDataIn );
            });
   
            modifData["data"] = subD;
            modifData["domain"] = [ Math.min(subD), Math.max(subD)];
            subcharts.push( modifData ); 
        });
  

        this.setState({ chart, subcharts });
    }

  
    componentDidMount () {
        this.makeChart()
    }

    handleClick = (data, index) => {
        this.setState({
          activeIndex: index,
          all: false
        });
      }

      showAllTrigg = () => {
          this.setState({ all : true,   activeIndex: -1 });
      }

      newTextkFormat = (e ) => { 
        return   e < 0  ? "Not_Attempted" : e;
      }
    
      
      showAllSubNow = () => {

        const { subcharts } = this.state;

        return( 

            <div className="row">

                {
                    subcharts.map( (each, index) =>  <div key={index} className="col-sm-12 com-md-6">
                        {
                            this.showMainSubchart( index)
                        }

                    </div>)
                   
                }

            </div>
        );
      }
      showMainSubchart = index => {

        const { chart, subcharts } = this.state;

        const nsubchart = subcharts[index]; 

         
         
        return(

            <div className="mt-5 px-3 py-4 bg-light pdf-chart" style={{ width: '100%', height: 350 }}>
            <h4 className="pl-3 heading-text mb-3">{nsubchart.name}</h4>
<ResponsiveContainer>
<LineChart 
        data={ nsubchart.data }
        margin={{
          top: 10, right: 30, left: 70, bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tickMargin ={6 }>
              <Label value="Attempts" offset={-10} position="insideBottomRight" />
            </XAxis>



            <YAxis 
            // type="category"  
            domain={ nsubchart.domain }
            tickFormatter={ this.newTextkFormat } />

           <Tooltip content={<CustomTooltip />} />
       
        <Legend />
        <Line type="monotone" dataKey="score" stroke="#8884d8" activeDot={{ r: 8 }} /> 
      </LineChart>

      </ResponsiveContainer>
        
          </div>


        );
        
    }



    mainChart = () => {

        const { chart, activeIndex } = this.state
        const activeItem = chart[activeIndex];

        return (
            <div style={{ width: '100%', height: 350 }}>
       
            <ResponsiveContainer>
           
          <BarChart 
            data={ chart }
            margin={{
              top: 10, right: 30, left: 20, bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
          
            <XAxis dataKey="name">
              <Label value="Games" offset={-10} position="insideBottom" />
            </XAxis>



            <YAxis  interval={1}  label={{ value: 'Count', angle: -90, position: 'insideLeft' }}/>
            <Tooltip /> 
            <Bar dataKey="count"  onClick={this.handleClick} > 
            {
                chart.map((entry, index) => (
                  <Cell cursor="pointer" key={index} fill={index === activeIndex ? '#82ca9d' : '#8884d8'} key={`cell-${index}`} />
                ))
              }
          </Bar>

          </BarChart>
          </ResponsiveContainer>
          </div>
        );
    }


  render() {
      const { chart, activeIndex, all } = this.state;
    return (
        <Fragment> 
        <div className="row">

            <div className="col-sm-12 pdf-chart">
                {
                   chart.length > 1 ? this.mainChart(  ) : <div />
                }
            </div>

        </div>

<div className="row">
     <div className="col-sm-12">
         <button className="ml-auto btn btn-info" onClick={ this.showAllTrigg }> view all</button>
     </div>
</div>

           

            {
                all ? this.showAllSubNow() :
                <Fragment>

    {
                    activeIndex > -1 ?
                    this.showMainSubchart( activeIndex):
                    <div />

                }

                </Fragment>
            }


    </Fragment>

       
    );
  }
}


export default siteCharts;