import React from 'react';
import { Redirect } from 'react-router-dom';  

import Login from '../components/Login';




const Page = ({   history, auth }) => {
    if (auth) return <Redirect to="/" />
 
    return (
        <div className="login">  
            <Login history={history}   />
        </div>
    );
}





export default Page; 
 
