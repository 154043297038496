import React from 'react'; 
import { BrowserRouter as Router, Switch, Route, withRouter} from 'react-router-dom';

import Login from '../pages/Login';
import Charts from '../pages/Charts';

 

function App( props ) { 
  let auth = localStorage.auth   ? true : false; 

  const logOut = e => { 
    console.log("exit");
    localStorage.removeItem("auth"); 
    auth = false;
    props.history.href= "/#/login"
  }
  return (
  <> 
  <Router>  
    <Switch> 
      <Route exact path="/login" render={() => ( <Login auth={ auth }  {...props} /> )} />
      <Route exact path="/" render={props => (<Charts  auth={ auth }   logOut={logOut} {...props} />)} />
    </Switch> 
  </Router> 
  </>
  );
}

export default App;
