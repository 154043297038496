import React, { PureComponent, Fragment } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  LineChart, Line, Label 

} from 'recharts';

 
 
class siteCharts extends PureComponent { 

    constructor( props ) {
        super( props );


        this.key_bo = {
          BL: 1,
          BM: 2,
          BR: 3,
          FL: 4,
          FM: 5,
          FR: 6
        }

        this.state = {
            chart: [],
            activeIndex: -1,
            subcharts: [],
            all: false,
            subcharts_new: {}
        }
    }

    getKyVal = (v, num = true ) => {
      let sc = 0;
      
      if(num) {
        sc = 0;


        try {
                let ad = 0; 
                ad = v.replace(" ","");
                ad = ad.replace("-","");
                ad = ad.replace("ALLSIDES","7");

                for( let ke in this.key_bo ) {
                  ad = ad.replace( ke, this.key_bo[ke]);
                }
  
                 
                sc = parseInt( ad );
              } catch (error) {  console.log( error);}
 



      } else {

        sc = "";

        try {


          let ad = v % 10;  

          for( let ke in this.key_bo ) {
            if( this.key_bo[ke] == ad ) 
            sc = `${parseInt( v / 10 )}-${ke}`;
            else if ( ad == 7) 
            sc = `${parseInt( v / 10 )}-ALL_SIDES`;
            
          }
 
        } catch (error) {  console.log( error);}

      }
       

      return sc;
    }


    getKyValB = (v  ) => {
      let sc = [0,0,0,0,0,0];

      try {

        const spp = v.split("-");
        const sssc = spp[1].split(":");
        sc = sssc.map( each => parseInt( each ));

        
      } catch (error) {
        
      }
      
 

      return sc;
    }

    makeChart = () => {
        const { data, datakey } = this.props;
        const des = [...datakey];
        des.splice(0, 5); 
        const chart = [];
        const ikeys = des;
        


        ikeys.forEach( (each, index) => {
            if( each.substr(0,1).toLowerCase() == "b" ){
                return;
            }

            const modifData = {};
                modifData["name"] = each;
                modifData["count"] = data.filter( eachIn => ! (  eachIn[each].replace(" ", "").toLowerCase() == "notattempted" ) ).length

            chart.push( modifData );
        });


        const subcharts = [];
        const subcharts_newb = [];


        ikeys.forEach( ( each, index ) => {
            const modifData = {};
            if( each.substr(0,1).toLowerCase() == "b" ){

              modifData["name"] = each;
              const subD = [];
  
           
              data.forEach( (eachIn, indexIn ) => {
                  const modifDataIn = {};
                  
  
                      modifDataIn["name"] = eachIn["date"];  
                      modifDataIn["score"] = ( eachIn[each] == "Not Attempted" ) ? -1 : this.getKyValB( eachIn[each]) ; 
                    if( eachIn[each] != "Not Attempted"  )
                    subcharts_newb.push( modifDataIn ); 
              });
  
              const subDm = [];
  
               
  
            


                return;
            }

            modifData["name"] = each;
            const subD = [];

        
            const ticks = [];
            data.forEach( (eachIn, indexIn ) => {
                const modifDataIn = {};
                

                    modifDataIn["name"] = eachIn["date"];  
                    modifDataIn["score"] = ( eachIn[each] == "Not Attempted" ) ? -1 : this.getKyVal( eachIn[each]) ; 

                    subD.push( modifDataIn );
                    ticks.push( modifDataIn["score"]);
            });

            const subDm = [];

             

            modifData["data"] = subD;
            modifData["domain"] = [ Math.min(subD), Math.max(subD)];
            modifData["ticks"] = ticks;
            

            
            subcharts.push( modifData ); 
        });
  
 

        let subcharts_new = {};

        try {

          let keyIndex = 0;
          for( let key in  this.key_bo  ) {

            subcharts_new[key] = subcharts_newb.map( each =>{ return {
              time: each.name,
              score: each.score[keyIndex]
            }
          });

            keyIndex++;
          }
          
        } catch (error) {
          console.error(error);
          
        } 
 

        this.setState({ chart, subcharts, subcharts_new });
    }

  
    componentDidMount () {
        this.makeChart()
    }

    handleClick = (data, index) => {
        this.setState({
          activeIndex: index,
          all: false
        });
      }

      showAllTrigg = () => {
          this.setState({ all : true,   activeIndex: -1 });
      }
    
      
      newTextkFormat = (e ) => {  
        return   e < 0  ? "Not_Attempted" :  this.getKyVal( e , false ) ;
      }
      
      showAllSubNow = () => {

        const { subcharts } = this.state;

        return( 

            <div className="row">

                {
                    subcharts.map( (each, index) =>  <div key={index} className="col-sm-12 com-md-6">
                        {
                            this.showMainSubchart( index)
                        }

                    </div>)
                   
                }

            </div>
        );
      }

      getBilliardChart   = ( nsubchart, name ) => {
 
 
  
        return(

            <div className="mt-5 px-3 py-4 bg-light pdf-chart" style={{ width: '100%', height: 350 }}>
            <h4 className="pl-3 heading-text mb-3">{  name }</h4>
<ResponsiveContainer>
<LineChart 
        data={nsubchart }
        margin={{
          top: 10, right: 30, left: 70, bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" tickMargin ={6 }>
              <Label value="Attempts" offset={-10} position="insideBottomRight" />
            </XAxis>



            <YAxis 
            // type="category"   
            interval={1}
              />

 
          
            <Tooltip  />
        <Legend />
        <Line type="monotone" dataKey="score" stroke="#8884d8" activeDot={{ r: 8 }} /> 
      </LineChart>

      </ResponsiveContainer>
        
          </div>


        );
        
    }
      showMainSubchart = index => {

        const { chart, subcharts } = this.state;

        const nsubchart = subcharts[index]; 
        return(

            <div className="mt-5 px-3 py-4 bg-light pdf-chart" style={{ width: '100%', height: 350 }}>
            <h4 className="pl-3 heading-text mb-3">{nsubchart.name}</h4>
<ResponsiveContainer>
<LineChart 
        data={nsubchart.data }
        margin={{
          top: 10, right: 30, left: 70, bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tickMargin ={6 }>
              <Label value="Attempts" offset={-10} position="insideBottomRight" />
            </XAxis>



            <YAxis 
            // type="category"  
            interval={0}
            ticks= {nsubchart.ticks }
            domain={ nsubchart.domain }
            tickFormatter={ this.newTextkFormat } />

 
          
            <Tooltip content={  this.CustomTooltip } />
        <Legend />
        <Line type="monotone" dataKey="score" stroke="#8884d8" activeDot={{ r: 8 }} /> 
      </LineChart>

      </ResponsiveContainer>
        
          </div>


        );
        
    }



    mainChart = () => {

        const { chart, activeIndex } = this.state;
        const activeItem = chart[activeIndex];

        return (
            <div style={{ width: '100%', height: 350 }}>
       
            <ResponsiveContainer>
           
          <BarChart 
            data={ chart }
            margin={{
              top: 10, right: 30, left: 20, bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
          
            <XAxis dataKey="name">
              <Label value="Games" offset={-10} position="insideBottom" />
            </XAxis>



            <YAxis  interval={1}  label={{ value: 'Count', angle: -90, position: 'insideLeft' }}/>
            <Tooltip /> 
            <Bar dataKey="count"  onClick={this.handleClick} > 
            {
                chart.map((entry, index) => (
                  <Cell cursor="pointer" key={index} fill={index === activeIndex ? '#82ca9d' : '#8884d8'} key={`cell-${index}`} />
                ))
              }
          </Bar>

          </BarChart>
          </ResponsiveContainer>
          </div>
        );
    }


    CustomTooltip = ({ active, payload, label }) => { 
      if (active) {
        const value = payload[0].value;
        return (
          <div className="custom-tooltip bg-white p-3 border">
            <p className="mb-2">{`Time : ${label}`}</p>
            <p className="m-0 strong">  {  value < 0 ? "Not Attempted" : <strong>{ ` Score : ${ this.getKyVal( value, false ) }` }</strong>}</p> 
          </div>
        );
      }
    
      return null;
    };
    

  render() {
      const { chart, activeIndex, all, subcharts_new } = this.state;
    return (
        <Fragment> 
        <div className="row">

            <div className="col-sm-12 pdf-chart">
                {
                   chart.length > 1 ? this.mainChart(  ) : <div />
                }
            </div>

        </div>

<div className="row mt-5">
     <div className="col-sm-12">
         <button className="ml-auto btn btn-info" onClick={ this.showAllTrigg }> view all</button>
     </div>
</div>

           

            {
                all ? this.showAllSubNow() :
                <Fragment>

    {
                    activeIndex > -1 ?
                    this.showMainSubchart( activeIndex):
                    <div />

                }

                </Fragment>
            }




{
  typeof subcharts_new["FR"] != "undefined" ?

<div className="row mt-5" >
     <div className="col-sm-12">
         <h2>Billiards Score Charts</h2>
     </div>
      <div className="col-sm-12 mt-3">

<Fragment> {  this.getBilliardChart(subcharts_new["FL"], "Forward Left"  )  }</Fragment>
<Fragment> {  this.getBilliardChart(subcharts_new["FM"], "Forward Middle"  )  }</Fragment>
<Fragment> {  this.getBilliardChart(subcharts_new["FR"], "Forward Right"  )  }</Fragment> 

<Fragment> {  this.getBilliardChart(subcharts_new["BL"], "Backward Left"  )  }</Fragment>
<Fragment> {  this.getBilliardChart(subcharts_new["BM"], "Backward Middle"  )  }</Fragment>
<Fragment> {  this.getBilliardChart(subcharts_new["BR"], "Backward Right"  )  }</Fragment> 

      </div>



</div>
: <div/>
  
}




    </Fragment>

       
    );
  }
}


export default siteCharts;